var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:_vm.index > 0 ? 'mt-2' : '',attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-text-field',{attrs:{"label":"Ext.","outlined":"","dense":"","hide-details":"","type":"number","min":"0","disabled":_vm.onlyShow},model:{value:(_vm.ext.ext),callback:function ($$v) {_vm.$set(_vm.ext, "ext", $$v)},expression:"ext.ext"}})],1),(!_vm.onlyShow)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){_vm.telefono.exts.length > 1
              ? _vm.deleteExtTelefonoContactHotel({ pos: _vm.posContact, posTelefono: _vm.posTelefono, index: _vm.index })
              : (_vm.ext.ext = null)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,4213127855)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_vm._e(),(!_vm.onlyShow && _vm.index === 0)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ml-7",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.adicionarExtTelefonoContactHotel({ pos: _vm.posContact, posTelefono: _vm.posTelefono })}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,3111435946)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }