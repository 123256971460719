<template>
  <v-row
    no-gutters
    :class="index > 0 ? 'mt-2' : ''"
  >
    <v-col
      cols="12"
      sm="8"
      md="8"
    >
      <v-text-field
        v-model="email.email"
        :label="$t('lbl.email')"
        outlined
        dense
        hide-details
        :disabled="onlyShow"
      ></v-text-field>
    </v-col>
    <v-col
      v-if="!onlyShow"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 ml-1"
            fab
            dark
            x-small
            color="error"
            @click="
              contactsHotel[pos].emails.length > 1 ? deleteEmailContactHotel({ pos, index }) : (email.email = null)
            "
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      v-if="!onlyShow && index === 0"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 ml-4"
            fab
            dark
            x-small
            color="primary"
            @click="adicionarEmailContactHotel(pos)"
          >
            <v-icon
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    email: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      contactsHotel: state => state.app.contactsHotel,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations(['deleteEmailContactHotel', 'adicionarEmailContactHotel']),
  },
}
</script>
