<template>
  <v-row
    no-gutters
    :class="index > 0 ? 'mt-2' : ''"
  >
    <v-col
      cols="12"
      sm="3"
      md="3"
    >
      <v-text-field
        v-model="telefono.code"
        :label="$t('lbl.code')"
        outlined
        dense
        hide-details
        type="number"
        min="0"
        prefix="+"
        class="mr-3"
        :disabled="onlyShow"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="3"
      md="3"
    >
      <v-text-field
        v-model="telefono.phone"
        :label="$t('lbl.telefono')"
        outlined
        dense
        hide-details
        type="number"
        min="0"
        :disabled="onlyShow"
      ></v-text-field>
    </v-col>

    <v-col
      v-if="!onlyShow"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 ml-1"
            fab
            dark
            x-small
            color="error"
            @click="
              contactsHotel[pos].telefonos.length > 1
                ? deleteTelefonoContactHotel({ pos, index })
                : (telefono.number = null)
            "
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      v-if="!onlyShow"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip
        v-if="index === 0"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1"
            fab
            dark
            x-small
            color="primary"
            @click="adicionarTelefonoContactHotel(pos)"
          >
            <v-icon
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>

    <v-col
      cols="12"
      sm="3"
      md="3"
    >
      <ExtTelefono
        v-for="(ext, i) in telefono.exts"
        :key="i"
        :pos-contact="pos"
        :pos-telefono="index"
        :index="i"
        :telefono="telefono"
        :ext="ext"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'

import ExtTelefono from './ExtTelefono.vue'

export default {
  components: {
    ExtTelefono,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    telefono: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      contactsHotel: state => state.app.contactsHotel,
    }),
  },
  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations(['deleteTelefonoContactHotel', 'adicionarTelefonoContactHotel']),
  },
}
</script>
