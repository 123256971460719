var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:_vm.index > 0 ? 'mt-2' : '',attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"label":_vm.$t('lbl.code'),"outlined":"","dense":"","hide-details":"","type":"number","min":"0","prefix":"+","disabled":_vm.onlyShow},model:{value:(_vm.telefono.code),callback:function ($$v) {_vm.$set(_vm.telefono, "code", $$v)},expression:"telefono.code"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.telefono'),"outlined":"","dense":"","hide-details":"","type":"number","min":"0","disabled":_vm.onlyShow},model:{value:(_vm.telefono.phone),callback:function ($$v) {_vm.$set(_vm.telefono, "phone", $$v)},expression:"telefono.phone"}})],1),(!_vm.onlyShow)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-1 ml-1",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){_vm.contactsHotel[_vm.pos].telefonos.length > 1
              ? _vm.deleteTelefonoContactHotel({ pos: _vm.pos, index: _vm.index })
              : (_vm.telefono.number = null)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,3332849118)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_vm._e(),(!_vm.onlyShow)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[(_vm.index === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-1",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.adicionarTelefonoContactHotel(_vm.pos)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,1212678235)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},_vm._l((_vm.telefono.exts),function(ext,i){return _c('ExtTelefono',{key:i,attrs:{"pos-contact":_vm.pos,"pos-telefono":_vm.index,"index":i,"telefono":_vm.telefono,"ext":ext}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }