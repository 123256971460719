<template>
  <v-row
    no-gutters
    :class="index > 0 ? 'mt-2' : ''"
  >
    <v-col
      cols="12"
      sm="8"
      md="8"
    >
      <v-text-field
        v-model="ext.ext"
        label="Ext."
        outlined
        dense
        hide-details
        type="number"
        min="0"
        :disabled="onlyShow"
      ></v-text-field>
    </v-col>

    <v-col
      v-if="!onlyShow"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-1"
            fab
            dark
            x-small
            color="error"
            @click="
              telefono.exts.length > 1
                ? deleteExtTelefonoContactHotel({ pos: posContact, posTelefono: posTelefono, index: index })
                : (ext.ext = null)
            "
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      v-if="!onlyShow && index === 0"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-7"
            fab
            dark
            x-small
            color="primary"
            @click="adicionarExtTelefonoContactHotel({ pos: posContact, posTelefono: posTelefono })"
          >
            <v-icon
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    ext: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    telefono: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    posContact: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posTelefono: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      contactsHotel: state => state.app.contactsHotel,
    }),
  },
  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations(['deleteExtTelefonoContactHotel', 'adicionarExtTelefonoContactHotel']),
  },
}
</script>
