<template>
  <v-col
    cols="12"
    md="4"
  >
    <Loading
      v-if="loading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          {{ $t('msg.deleteImg') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card v-if="!isLoading">
      <v-card-text>
        <p class="text--primary text-base">
          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
            >
              <v-img
                lazy-src="@/assets/images/placeholder/hotel_placeholder.jpg"
                :src="image.resource === 's3' ? rutaS3 + image.image : rutaPublic + image.image"
                height="200"
              ></v-img>
              <span
                :style="img.width !== 1080 || img.height !== 720 ? 'color: red' : ''"
              >
                {{ img.width }} x {{ img.height }}
              </span>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="pt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-switch
                v-model="image.principal"
                :label="$t('lbl.principal')"
                color="primary"
                hide-details
                class="mb-2"
                :disabled="$store.state.app.onlyShow || editando || !verifyImage"
                @click="
                  $emit('change-cumple-tags')
                  updateImg()
                "
              ></v-switch>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-select
                v-model="image.tag"
                :items="tags"
                label="Tag"
                outlined
                dense
                item-text="name"
                item-value="id"
                hide-details
                :disabled="$store.state.app.onlyShow || editando"
                @change="
                  $emit('change-cumple-tags')
                  updateImg()
                "
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="1"
              md="1"
            >
              <v-tooltip
                v-if="!$store.state.app.onlyShow"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteImg"
                  >
                    <v-icon small>
                      {{ icons.mdiTrashCan }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              sm="1"
              md="1"
            >
              <v-tooltip
                v-if="!$store.state.app.onlyShow"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-4"
                    fab
                    dark
                    x-small
                    color="primary"
                    v-bind="attrs"
                    :disabled="verifyImage"
                    v-on="on"
                    @click="crops()"
                  >
                    <v-icon small>
                      {{ icons.mdiCrop }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.resize') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiEyePlus,
  mdiCrop,
} from '@mdi/js'

export default {
  components: {
    Loading,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    image: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiEyePlus,
        mdiCrop,
      },
      model: {},
      tags: [],
      loading: false,
      isDialogVisibleDelete: false,
      editando: false,
      img: new Image(),
    }
  },
  computed: {
    verifyImage() {
      let resul = false
      if (this.img.width && this.img.height) {
        if (this.img.width === 1080 && this.img.height === 720) {
          resul = true
        }
      }

      return resul
    },
  },
  mounted() {
    this.img.src = this.image.resource === 's3' ? this.rutaS3 + this.image.image : this.rutaPublic + this.image.image

    this.img.onload = () => {
      if (this.img.width > 1080 || this.img.height > 720) {
        this.image.crop = false
      }
      setTimeout(() => {
        this.isLoading = false
      }, 100)
    }
  },
  created() {
    this.loadTags()
  },
  methods: {
    ...mapMutations([
      'setImgLoading',
    ]),
    loadTags() {
      this.axios
        .get('nom_tag_imagen_hotels?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          this.tags = per.data.data
        })

      /* .finally(() => {
          this.isLoading = false
        }) */
    },
    ...mapMutations(['setOnlyShow']),
    crops() {
      if (this.image.image) {
        sessionStorage.setItem('path-img-crop', this.image.image)
        sessionStorage.setItem('resource-img-crop', this.image.resource)
        this.$router.push({ name: 'hotels-update-crop' })
      }
    },
    updateImg() {
      this.editando = true
      this.image.product = 'hotel'
      this.image.product_id = sessionStorage.getItem('hotels-id')
      this.axios
        .post('galery/img-update', this.image, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {})
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.editando = false))
    },

    deleteImg() {
      this.isDialogVisibleDelete = true
    },
    confirmDelete() {
      this.loading = true
      this.isDialogVisibleDelete = false
      this.image.product = 'hotel'
      this.image.product_id = sessionStorage.getItem('hotels-id')
      this.axios
        .post('galery/img-delete', this.image, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$emit('resetImagen')
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.$emit('reset-galery-item')
        })
    },
  },
}
</script>
